import React, { Suspense, lazy,useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { themeChange } from 'theme-change';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/common/Loader';

const Home = lazy(() => import('./containers/Home'));
const Login = lazy(() => import('./containers/Login'));
const ForgotPassword = lazy(() => import('./containers/ForgotPassword'));
const VarifyEmailForgotPassword = lazy(() => import('./containers/VarifyEmailForgotPassword'));
const Signup = lazy(() => import('./containers/Signup'));
const LearnerDashboard = lazy(() => import('./containers/learner/Dashboard'));
const AmbassadorDashboard = lazy(() => import('./containers/ambessador/Dashboard'));
const Ownerdashboard = lazy(() => import('./containers/owner/Dashboard'));
const Subscription = lazy(() => import('./containers/learner/Subscription'));
const AmbassadorSubscription = lazy(() => import('./containers/ambessador/Subscription'));
const PrivacyPolicy = lazy(() => import('./containers/Privacy-policy'));
const TermsOfService = lazy(() => import('./containers/Terms-of-service'));
const Courses = lazy(() => import('./containers/store/Courses'));
const CoursesDetails = lazy(() => import('./containers/store/CoursesDetails'));
const BecomeAmbassador = lazy(() => import('./containers/store/BecomeAmbassador'));
const Cart = lazy(() => import('./containers/store/Cart'));
const OrderHistory = lazy(() => import('./containers/learner/OrderHistory'));
const MyCourses = lazy(() => import('./containers/learner/MyCourses'));
const PremiumCourses = lazy(() => import('./containers/store/PremiumCourses'));
const UpdateProfile = lazy(() => import('./containers/learner/UpdateProfile'));
const AmbassadorUpdateProfile = lazy(() => import('./containers/ambessador/UpdateProfile'));
const AboutUs = lazy(() => import('./components/common/AboutUs'));
const FAQs = lazy(() => import('./components/common/FAQs'));
const HowItWorks = lazy(() => import('./components/common/HowItWorks'));
const ContactUs = lazy(() => import('./components/common/ContactUs'));

const AdminLogin = lazy(() => import('./containers/admin/Login'));
const AdminForgotPassword = lazy(() => import('./containers/admin/ForgotPassword'));
const AdminVarifyEmailForgotPassword = lazy(() => import('./containers/admin/VarifyEmailForgotPassword'));
const AdminDashboard = lazy(() => import('./containers/admin/AdminDashboard'));
const LinkReferralCode = lazy(() => import('./containers/admin/LinkReferralCode'));
 

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
    
    // To scroll page to top while open
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
      <Routes>
            <Route path="/*" element={
              <Suspense fallback={<Loader/>}>
                <PrivateRoutes />
              </Suspense>
            } />

            <Route path="/admin/*" element={
              <Suspense fallback={<Loader/>}>
                <AdminRoutes />
              </Suspense>
            } />
      </Routes>
  );
}
export default App;


export const PrivateRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/forgot-password' element={<ForgotPassword/>} />
        <Route path='/varify-email-forgot-password' element={<VarifyEmailForgotPassword/>} />
        <Route path='/learner/dashboard/success' element={<LearnerDashboard/>} />
        <Route path='/cart/cancel' element={<Cart/>} />
        <Route path='/learner/dashboard/notify' element={<LearnerDashboard/>} />
        <Route path='/signup' element={<Signup/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/terms-of-service' element={<TermsOfService/>} />
        <Route path='/browse-courses' element={<Courses/>} />
        <Route path='/courses-details' element={<CoursesDetails/>} />
        <Route path='/become-ambassador' element={<BecomeAmbassador/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/premium-courses' element={<PremiumCourses/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/faqs' element={<FAQs/>} />
        <Route path='/how-it-works' element={<HowItWorks/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/learner/dashboard' element={<LearnerDashboard/>} />
        <Route path='/learner/subscription' element={<Subscription/>} />
        <Route path='/learner/order-history' element={<OrderHistory/>} />
        <Route path='/learner/my-courses' element={<MyCourses/>} />
        <Route path='/learner/updateprofile' element={<UpdateProfile/>} />
        <Route path='/ambessador/ambassador-subscription' element={<AmbassadorSubscription/>} />
        <Route path='/ambessador/ambassador-update' element={<AmbassadorUpdateProfile/>} />
        <Route path='/ambessador/dashboard' element={<AmbassadorDashboard/>} />
        <Route path='/ambessador/dashboard/success' element={<AmbassadorDashboard/>} />
        <Route path='/ambessador/cart/cancel' element={<Cart/>} />
        <Route path='/ambessador/dashboard/notify' element={<AmbassadorDashboard/>} />
        <Route path='/owner/dashboard' element={<Ownerdashboard/>} />
      </Routes>
    </>
  )
}


export const AdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/login' element={<AdminLogin/>} />
        <Route path='/forgot-password' element={<AdminForgotPassword/>} />
        <Route path='/varify-email-forgot-password' element={<AdminVarifyEmailForgotPassword/>} />
        <Route path='/admin-dashboard' element={<AdminDashboard/>} />
        <Route path='/link-referral-code' element={<LinkReferralCode/>} />
      </Routes>
    </>
  )
}
